import { backendApi } from "../../backend-api-sr";

export const woAprvConfig = (() =>{
    const fetcIndex = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo_apprv_config'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const store = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo_apprv_config/store'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const update = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo_apprv_config/update'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetcMill = ( async (subPath) => {
        let url = '/api/viva/maintenance/wo_apprv_config/mill'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    });
    const fetcMachType = ( async (subPath) => {
        let url = '/api/viva/maintenance/wo_apprv_config/machine_type'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    });
    const fetcMachId = ( async (subPath) => {
        let url = '/api/viva/maintenance/wo_apprv_config/machine_id'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    });
    const fetcMaintSection = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo_apprv_config/maint_section'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetcEmployee = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo_apprv_config/employee'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });

    return {fetcIndex, store, update, fetcMill, fetcMachType, fetcMachId, fetcMaintSection, fetcEmployee};
})()