import { backendApi } from "../../backend-api-sr";

export const woHeader = (() =>{
    const fetchIndex = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/header'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const store = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/header/store'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const update = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/header/update'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetchCause = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/cause'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const storeCause = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/cause/store'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const updateCause = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/cause/update'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const deleteCause = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/cause/delete'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetchUnplannedCause = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/cause/unplanned'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const storeUnplannedCause = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/cause/store-unplanned'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const updateUnplannedCause = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/cause/update-unplanned'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const deleteUnplannedCause = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/cause/delete-unplanned'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetchTimeRecord = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/time_record'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const storeTimeRecord = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/time_record/store'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const updateTimeRecord = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/time_record/update'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const deleteTimeRecord = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/time_record/delete'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetchManPower = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/wo_man_power'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const storeManPower = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/wo_man_power/store'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const updateManPower = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/wo_man_power/update'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const deleteManPower = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/wo_man_power/delete'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetchSparePartReq = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/sparepart_req'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const storeSparePartReq = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/sparepart_req/store'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const updateSparePartReq = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/sparepart_req/update'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const deleteSparePartReq = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/wo/sparepart_req/delete'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetchMill = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/shared/mill'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetchMachType = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/shared/machine_type'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetchMachId = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/shared/machine_id'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetchMaintSection = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/shared/maint_section'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetchPartList = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/shared/part_list'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetchEmployee = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/shared/employee'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const checkListOutstanding = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/shared/checklist_outstanding'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const currentSoh = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/shared/current_soh'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const downtimeOutstanding = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/shared/downtime-outstanding'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const department = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/shared/department'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const reason = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/shared/reason'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });
    const fetchReasonCategory = ( async (subPath, reqBody) => {
        let url = '/api/viva/maintenance/shared/unplanned-reason-category'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    });

    return {fetchIndex, store, update,
        fetchCause, storeCause, updateCause, deleteCause,
        fetchUnplannedCause, storeUnplannedCause, updateUnplannedCause, deleteUnplannedCause,
        fetchTimeRecord, storeTimeRecord, updateTimeRecord, deleteTimeRecord,
        fetchManPower, storeManPower, updateManPower, deleteManPower,
        fetchSparePartReq, storeSparePartReq, updateSparePartReq, deleteSparePartReq,
        fetchMill, fetchMachType, fetchMachId, fetchMaintSection, fetchPartList, fetchEmployee, checkListOutstanding, currentSoh,
        downtimeOutstanding, department, reason, fetchReasonCategory};
})()